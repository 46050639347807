<template>


    <fieldset>
        <legend><b-badge class="legend-title">Encuestas</b-badge></legend>


        <router-view></router-view>


    </fieldset>

</template>

<script>


    export default {
        name: 'Surveys',

    }

</script>



<style>
</style>
